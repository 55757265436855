body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}
header h1 {
  text-align: center;
}
main {
  margin: 0 0;
}

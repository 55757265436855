.TweetHeatMap {
  margin: auto;
  position: relative;
  width: 100%;
  height: 85vh;
  text-align: center;
}
.TweetHeatMap .GoogleMap {
  margin: auto;
  width: 100%;
  height: 85vh;
}
.TweetHeatMap .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px;
}
.TweetHeatMap form.searchBar {
  margin: 1em auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TweetHeatMap form.searchBar input,
.TweetHeatMap form.searchBar select {
  width: 10em;
}
.TweetHeatMap form.searchBar label {
  padding-left: 1em;
  padding-right: 0.25em;
}
